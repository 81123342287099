.modal-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.modal-container {
  border-radius: 10px;
  overflow: hidden;
}

.cell-border-right {
  border-right: 1px solid rgba(224, 224, 224, 1);
}
 

@media (max-width: 600px) {
  .modal-box {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 8px !important;
  }
}

@media (min-width :287px) and (max-width: 335px) {
  .fileuploadeditrfp {
    height: 90% !important;
    width: 260px !important;
  }
}

@media (min-width :336px) and (max-width: 600px) {
  .fileuploadeditrfp {
    height: 90% !important;
    width: 300px !important;
  }
}

@media (max-width: 887px) {
  .box {
    padding: 15px;
    width: 100%;
  }

  .table-container {
    padding: 2px;
  }

  .MuiButton-AddNew {
    padding: 10px 15px 10px 15px !important;
    /* Adjust each value as needed */
    font-size: 10px !important;
    transform: scale(1.0) !important;
  }


  .Buttons_ESD1 {
    transform: scale(0.9) !important;
    position: relative;
    top: -5px;
    left: 7px;
  }

  .Buttons_ESD {
    transform: scale(0.9) !important;
    position: relative;
    top: 8px;
    left: 25px;
  }

  .Buttons_ESD4 {
    position: relative;
    top: 9px
  }


  .MuiTableCell-root {
    font-size: 12px;
  }

  .MuiButtonBase-root {
    font-size: 14px;
  }

  .MuiTypography-h5 {
    font-size: 16px;
  }

  .Buttons1 {
    padding: '5px 5px' !important;
  }

  .table-container .MuiTableCell-root {
    font-size: 11px;
    /* max-width: 70px; */
  }

  .css-tqpxs0-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
    font-size: 11px;
  }
}

@media (max-width: 326px) {
  .quill-editor {
    height: 200px;
    /* Adjust height for mobile view */
  }

  .paperss {
    height: 90rex;
  }

  .save-button {
    position: relative;
    top: 104px;
  }

  .addnewsection {
    font-size: 10px !important;
  }
}

@media(min-width:327px) and (max-width: 384px) {
  .quill-editor {
    height: 200px;
    /* Adjust height for mobile view */
  }

  .paperss {
    height: 86rex;
  }

  .save-button {
    position: relative;
    top: 84px;
  }

  .addnewsection {
    font-size: 10px !important;
  }
}

@media(min-width:385px) and (max-width: 435px) {
  .quill-editor {
    height: 200px;
  }

  .paperss {
    height: 83rex;
  }

  .save-button {
    position: relative;
    top: 60px;
  }

  .addnewsection {
    font-size: 10px !important;
  }
}

@media(min-width:436px) and (max-width: 511px) {
  .quill-editor {
    height: 200px;
  }

  .paperss {
    height: 78rex;
  }

  .save-button {
    position: relative;
    top: 37px;
  }

  .addnewsection {
    font-size: 10px !important;
  }
}

@media (max-width: 375px) {
  .buttonsd {
    padding: 12px 24px !important;
    margin: 10px 2px;

  }
}

@media (max-width: 320px) {
  .buttonsrfp {
    margin-top: 150px !important;
  }

  .buttonbackss {
    height: 30px !important;
    width: 15px !important;
    position: relative;
    left: 11.5rem;
  }

  .formesd {
    margin-top: -30px !important;
  }
}

@media (min-width: 322px) and (max-width: 386px) {
  .buttonsrfp {
    margin-top: 130px !important;
  }

  .buttonbackss {
    height: 30px !important;
    width: 15px !important;
    margin-left: -30px !important;
  }
}

@media (min-width: 387px) and (max-width: 425px) {
  .buttonsrfp {
    margin-top: 120px !important;
  }

  .buttonbackss {
    height: 30px !important;
    width: 15px !important;
    margin-left: 6px !important;
  }
}


@media (min-width: 375px) and (max-width:449px) {
  .buttonsrfp {

    padding-top: -30px !important;

  }

  .buttonbackss {
    height: 30px !important;
    width: 15px !important;
    display: flex;
    position: relative !important;
    left: 19rem !important;
    top: -30px !important;
  }

  .formesd {
    margin-top: -45px !important;
  }
}

@media(min-width:768px) and (max-width:2560px) {
  .buttonsrfp {

    margin-top: 60px !important;

  }
}

@media (max-width: 381px) {
  .buttonsd {
    height: 30px !important;
    width: 15px !important;
  }
}
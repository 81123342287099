
.img-login {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}


.login-container {
  height: 100vh;
}


.hide-on-mobile {
  display: block;
}


@media (max-width: 600px) {
    .hide-on-mobile {
      display: none; /* Hides the image on mobile */
    }
  
    * {
      font-size: 12px; 
    }
  
    .scale-down {
      transform: scale(0.5);
      transform-origin: top right;
    }
  
    .text11 {
      transform: scale(0.9);
      transform-origin: top left;
      margin-bottom: -10px;
      margin-top: 10px;
    }
  
    .createpagetextfields {
      width: 100% !important;
    }
  }


  @media (min-width: 601px) {
    .scale-down {
        transform: scale(0.7) !important;
        transform-origin: top right;
     
      }
      .text11{
        transform: scale(0.8);
        transform-origin: top left;
        margin-bottom: -10px;
        margin-top: 10px;
      }
  }
 
  
  @media (min-width: 600px) and (max-width: 960px) {

    .form-container {
      width: 100%;
      margin-left: auto;
    }
    .scale-down {
      transform: scale(0.6) !important;
      transform-origin: top right;
   
    }
  }


  @media (min-width: 606px) and (max-width: 892px) {
    .textfieldotp  {
     transform: scale(80%) !important;
    }
  }

  @media (min-width : 300px) and (max-width:345px){

    .welcometxtlogin{
      transform: scale(130%) !important;
    position: absolute;
    top: 88px;
    left: 57px;
    }

  }

  @media (min-width : 346px) and (max-width:379px){

    .welcometxtlogin{
      transform: scale(130%) !important;
      position: absolute;
      top: 95px;
      left: 64px;
    }

  }

  @media (min-width : 380px) and (max-width:435px){

    .welcometxtlogin{
      transform: scale(140%) !important;
      position: absolute;
      top: 88px;
      left: 78px;
    }

  }
  
  @media (min-width : 602px) and (max-width:780px){
    .loginfullcomp{
      transform: scale(50%) !important;
    }

    .welcometxtlogin{
      transform: scale(80%) !important;
      position: relative !important;
      top: 20px !important;
      right: 20px !important;
    }
  }

  @media (min-width : 790px) and (max-width:1028px){
    .loginfullcomp{
      transform: scale(60%) !important;
    }

    .welcometxtlogin{
      transform: scale(80%) !important;
      position: relative !important;
      top: 15px !important;
      right: 25px !important;
    }

  }

  @media (min-width : 962px) and (max-width:1180px){
   .cretenewacnttxt{
    margin-top: 25px !important;
   }
  }

  @media (min-width : 300px) and (max-width:338px){
    .cretenewacnttxt{
      transform: scale(1.4) !important;
      position: absolute;
     left: 25px;
     bottom: 50px; 
    }
  }

  @media (min-width : 340px) and (max-width:380px){
    .cretenewacnttxt{
      transform: scale(1.4) !important;
      position: absolute;
      left: 25px;
      bottom: 50px; 
    }
  }

  @media (min-width : 387px) and (max-width:435px){
    .cretenewacnttxt{
      transform: scale(1.4) !important;
      position: absolute;
      left: 25px;
      bottom: 50px; 
    }
  }

  /* verify otp */

  @media (min-width : 300px) and (max-width:435px){

    .verifyotptxt{
      transform: scale(120%) !important;
       position: absolute !important;
      left: 43px !important;
      top: 95px !important; 
    }
  }

  @media (min-width : 352px) and (max-width:393px){
    .verifyotptxt{
      transform: scale(120%) !important;
       position: absolute !important;
       left: 48px !important;
       top: 95px !important; 
    }
  }

  @media (min-width : 395px) and (max-width:430px){
    .verifyotptxt{
      transform: scale(120%) !important;
       position: absolute !important;
       left: 53px !important;
       top: 95px !important; 
    }
  }

  @media (min-width : 660px) and (max-width:790px){
    .verifyotptxt{
      transform: scale(80%) !important;
      position: relative ;
      right: 19px;
      top: 10px;  
    }
  }

  @media (min-width : 800px) and (max-width:1040px){
    .verifyotptxt{
      transform: scale(90%) !important;
       position: relative;
      right: 18px;
      top: 10px; 
    }
  }

  /*forgot pass*/

  @media (min-width : 300px) and (max-width:435px){
    .forgotpswtxt{
      transform: scale(120%) !important;
       position: absolute;
      left: 55px;
      top: 150px; 
    }
  }

  @media (min-width : 352px) and (max-width:393px){
    .forgotpswtxt{
      transform: scale(120%) !important;
       position: absolute;
       left: 63px;
       top: 150px; 
    }
  }

  @media (min-width : 395px) and (max-width:430px){
    .forgotpswtxt{
      transform: scale(120%) !important;
       position: absolute;
       left: 68px;
      top: 150px; 
    }
  }

  @media (min-width : 660px) and (max-width:790px){
    .forgotpswtxt{
      transform: scale(80%) !important;
       position: absolute;
       left: 28px;
       top: 105px;  
    }
  }

  @media (min-width : 800px) and (max-width:1040px){
    .forgotpswtxt{
      transform: scale(80%) !important;
       position: absolute;
       left: 38px;
       top: 105px; 
    }
  }
  
  /*new password*/

  @media (min-width : 300px) and (max-width:435px){
    .newpasschangetxt{
      transform: scale(120%) !important;
       position: relative;
      left: 22px;
      top: 20px; 
    }

    .nprfptext{
      font-size: 12px !important;
      margin-top: 22px !important;
    }
  }

  @media (min-width : 352px) and (max-width:393px){
    .newpasschangetxt{
      transform: scale(120%) !important;
       position: relative;
      left: 26px;
      top: 20px; 
    }

    .nprfptext{
      font-size: 12px !important;
      margin-top: 22px !important;
    }
  }

  @media (min-width : 395px) and (max-width:430px){
    .newpasschangetxt{
      transform: scale(120%) !important;
       position: relative;
      left: 30px;
      top: 3px; 
    }

    .nprfptext{
      font-size: 12px !important;
      margin-top: 5px !important;
    }
  }

  @media (min-width : 660px) and (max-width:790px){
    .newpasschangetxt{
      transform: scale(80%) !important;
       position: relative;
      right: 23px;
      top: 18px; 
    }

    .nprfptext{
      font-size: 14px !important;
      margin-top: 16px !important;
    }
  }

  @media (min-width : 800px) and (max-width:1035px){
    .newpasschangetxt{
      transform: scale(80%) !important;
       position: relative;
      right: 31px;
      top: 10px; 
    }

    .nprfptext{
      font-size: 15px !important;
      margin-top: 8px !important;
    }
  }

  @media (min-width : 1040px) and (max-width:2562px){

    .nprfptext{
      font-size: 18px !important;
      margin-top: 1px !important;
    }
  }

  /* arrow in login */
  @media (min-width : 300px) and (max-width:389px){
    .arrowauthl{
      transform: scale(80%) !important;
      position: relative !important;
      right: 45px !important;

    }
  }

  @media (min-width : 391px) and (max-width:435px){
    .arrowauthl{
      transform: scale(80%) !important;
      position: relative !important;
      right: 28px !important;

    }
  }

  @media (min-width : 495px) and (max-width:798px){
    .arrowauthl{
      transform: scale(90%) !important;
      position: relative !important;
      right: 38px !important;

    }
  }

  @media (min-width : 800px) and (max-width:1045px){
    .arrowauthl{
      transform: scale(90%) !important;
      position: relative !important;
      right: 22px !important;

    }
  }
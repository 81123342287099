.dyhgHY{
    padding: 0px!important;
}
.gGYaoy{
    padding: 0px!important;
}

.inputfields .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0D6EFD !important;
}
.inputfields .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0D6EFD;
  }
 

@media (min-width: 426px) and (max-width: 1000px) {
  

    .selectField {
        width: 9rem !important;
        border-radius: 12px !important;
    }
    .headings{
        font-size: 16px!important;
    }
    
}

@media (min-width: 376px) and (max-width: 425px) {


    .selectField {
        width: 100% !important;
        border-radius: 12px !important;
    }
    .headings{
        font-size: 14px!important;
    }
}



@media (min-width: 0px) and (max-width: 320px) {


    .statusGraph {
        height: 45% !important;
    }
    .recharts-legend-wrapper{
        bottom: unset !important;
    }

    .selectField {
        width: 8rem !important;
        border-radius: 12px !important;
    }
    .headings{
        font-size: 12px!important;
    }
    /* .chart-surface{
        height: 75%!important;
      
    } */
     .names{
        font-size: 11px!important;
        padding-left: 20px;
     }
     .totalAmount{
        position: absolute;
        top: 31%;
     }
}


@media (min-width: 321px) and (max-width: 375px) {
 

    .statusGraph {
        height: 55% !important;
    }
    .recharts-legend-wrapper{
        bottom: unset !important;
    }

    .selectField {
        width: 9rem !important;
        border-radius: 12px !important;
    }
    .headings{
        font-size: 14px!important;
    }
    .totalAmount{
        position: absolute;
        top: 38%;
     }
}

@media (min-width: 321px) and (max-width: 355px) {


    .statusGraph {
        height: 47% !important;
    }
   
}

@media (min-width: 376px) and (max-width: 425px) {


    .statusGraph {
        height: 74% !important;
    }
    .recharts-legend-wrapper{
        bottom: unset !important;
    }

    .selectField {
        width: 12rem !important;
        border-radius: 12px !important;
    }
    .headings{
        font-size: 14px!important;
    }

    .totalAmount{
        position: absolute;
        top: 40%;
     }
}

@media (min-width: 424px) and (max-width: 500px) {
    .statusGraph {
        height: 77% !important;
    }
   
}

@media (min-width:501px) and (max-width:1000px){
    .totalAmount{
        position: absolute;
        top: 50%;
     }

}


@media (min-width: 1001px) and (max-width: 3000px) {
    .selectField {
        width: 13rem !important;
        border-radius: 12px !important;
    }
    .headings{
        font-size: 16px!important;
    }
    .totalAmount{
        position: absolute;
        top: 40%;
     }

}
#chat-messages {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.user-message {
  text-align: right;
  padding: 5px 20px;
  border: none;
  border-radius: 30px 0px 30px 30px;
  background-color: #4b7bec;
  color: white;
  width: 50%;
  float: right;
  font-size: 12px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.bot-message {
  text-align: left;
  color: #333;
  width: 50%;
  margin-top: 10px;
  background-color: #EEEEEE;
  padding: 5px 20px;
  float: left;
  border-radius: 30px 30px 30px 0px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;

}

.chat-container {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.input_field {
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-self: center;
  margin: 20px;
}

#user-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

#user-input:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border: none;
}

#user-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  font-size: 11px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

#user-input:focus {
  outline: none;
  box-shadow: 0px 0px 5px rgba(75, 123, 236, 0.5);
  outline-width: 0.5px;
  outline-style: solid;
  outline-color: #0175FF;
}

.sendImage {
  position: relative;
  cursor: pointer;
  right: 52px;
  padding-top: 9px;
}

.voice-icon {
  position: relative;
  right: 55px;
  cursor: pointer;
  color: #007bff;
  padding-top: 9px;
}

.voice-icon:hover {
  color: #0056b3;
}

.loading-spinner {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media(min-width:200px) and (max-width: 425px) {
  .form-control::placeholder {
    font-size: 9px !important;
  }

  .buttonslastrfp {
    transform: scale(70%) !important;
    position: relative !important;
    right: 10px !important;
    bottom: 4px !important;
  }

  .chatbotreplytext{
    font-size: 8px !important;
  }

  .chatbotroboticon{
    transform: scale(70%);
  }

  .chatbotusericon{
    transform: scale(70%);
  }
  
  .bot-message{
  width: 100% !important;
  }
}
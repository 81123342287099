.css-13n728z{
    padding:5px!important;
    width: 0px!important;
   
}
.css-1jp64pm-MuiPaper-root-MuiAlert-root{
    padding:3px 5px !important
}


@media (min-width: 320px){
   .notificationBox{
    width: 290px;
    height: 400px!important;
    
   } 

   .textname{
    font-size: 9px!important;
    width: 20px!important;
   }
   .main_text{
    font-size: 10px!important;
   }
   .sub_text{
    font-size: 9px!important;
   }
}

@media(min-width:426px) and (max-width:768px){
    .notificationBox{
        width: 530px;
        height: 400px!important;
       } 
      
       .textname{
        font-size: 9px!important;
        width: 139px!important;
       }
       .main_text{
        font-size: 11px!important;
       }
       .sub_text{
        font-size: 10px!important;
       } 
}

@media(min-width:769px) and (max-width:3000px){
    .notificationBox{
        width: 650px;
        height: 600px!important;
       } 
      
       .textname{
        font-size: 12px!important;
        width: 185px!important;
       }
       .main_text{
        font-size: 13px!important;
       }
       .sub_text{
        font-size: 12px!important;
       } 
}

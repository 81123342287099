.button-enabled {
    background-color: #0175FF !important;
    color: white !important;
}

.button-disabled {
    background-color: grey !important;
    color: white !important;
}

.menuItem {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16.8px !important;
    color: #0075ff !important;
}

.subheader {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #565656 !important;
}

.css-1wx3v6p-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator {
    margin: 0;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.167;
    -webkit-transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(0, 0, 0, 0.6);
    margin: 10px 4px 0 2px !important;
    cursor: default;
}

.css-1j9v0by-MuiClock-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 50px !important;
}
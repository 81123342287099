.pro-sidebar.collapsed{
   width: 62px;
   min-width: 62px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content{
    overflow: visible!important;
}

.submenu-item {
    margin-left: 20px;
    padding-left: 30px;
    transition: margin-left 0.3s ease, padding-left 0.3s ease;
  }
  
  .submenu-item.collapsed {
    margin-left: -40px;
    padding-left: 10px;
  }

@media (max-width:600px){

}
.fontstyleeb {
  font-family: 'Arial', sans-serif;
}

.bidSmartText {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.responsiveBox {
  display: flex;
  align-items: center;
}

.robotImage {
  width: 40px;
  height: 40px;
}

.topbarr {
  display: flex;
  align-items: center;
}

@media (max-width: 320px) {

  .manIcon {
    width: 20px !important;
    height: 20px !important;
  }
  .moreIcon{
    width: 75%!important;
  }
  .css-1serysv {
   
    padding-right: 10px!important;
}

  .robotImage {
    width: 28px;
    height: 28px;
  }
  .bidSmartText {
    font-size: 11px !important;
  }

  .userName,.welcomeTo {
    font-size: 8px !important;
    font-family: 'Arial', sans-serif;
  }
  .dangerIcon{
    width: 23px!important;
    height: 23px!important;
  }
  .notification_danderIcon{
   width: 12px!important;
   height: 12px!important;
  }
  .css-zv6syv{
    width: 0px!important;;
  }
  .userRoleText{
    font-size: 8px!important;
  }
  .main_topbar{
    padding-left: 9px!important;
    padding-right: 13px!important;
    padding-top: 12px!important;
  
  }
  
}


@media (min-width: 321px) and (max-width: 390px) {
  
  .manIcon {
    width: 23px !important;
    height: 23px !important;
  }
  .moreIcon{
    width: 75%!important;
  }
  .css-1serysv {
   
    padding-right: 10px!important;
}

  .robotImage {
    width: 30px;
    height: 30px;
  }
  .robotImageclass{
    padding-left: 10px;
  }
  .bidSmartText {
    font-size: 13px !important;
  }
  
  .userName,.welcomeTo  {
    font-size: 8px !important;
    font-family: 'Arial', sans-serif;
  }
  .dangerIcon{
    width: 23px!important;
    height: 23px!important;
  }
  .notification_danderIcon{
   width: 12px!important;
   height: 12px!important;
  }
  .css-zv6syv{
    width: 0px!important;;
  }
   .userRoleText{
    font-size: 9px!important;
  }

  
}
@media (min-width: 376px) and (max-width: 460px) {
  .bidSmartText {
    font-size: 13px !important;
  }
}
@media (min-width: 461px) and (max-width: 600px) {
  .bidSmartText {
    font-size: 20px !important;
  }
}

@media (min-width: 376px) and (max-width: 600px) {
  
  .manIcon {
    width: 25px !important;
    height: 25px !important;
  }
  .moreIcon{
    width: 75%!important;
  }
  .robotImageclass{
    padding-left: 10px;
  }

  .robotImage {
    width: 33px;
    height: 33px;
  }
 

  .userName,.welcomeTo {
    font-size: 9px !important;
    font-family: 'Arial', sans-serif;
  }
  .dangerIcon{
    width: 26px!important;
    height: 26px!important;
  }
  
  .userRoleText{
    font-size: 9px!important;
  }
  
}

@media (min-width: 601px) and (max-width: 1024px) {
  .responsiveBox {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .bidSmartText {
    font-size: 16px!important;
  }
  .robotImageclass{
    padding-left: 10px;
  }

  .robotImage {
    width: 35px;
    height: 35px;
  }

  .userName,.welcomeTo {
    font-size: 16px;
    font-family: 'Arial', sans-serif;
  }
    
  .userRoleText{
    font-size: 10px!important;
  }
  .dangerIcon{
    width: 30px!important;
    height: 30px!important;
  }
  .manIcon {
    width: 28px !important;
    height: 28px !important;
  }
  .userName{
    font-size: 11px!important; 
  }
  .moreIcon{
    width: 78%!important;
  }

}

@media (min-width: 1025px) {
  .responsiveBox {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .bidSmartText {
    font-size: 21px;
  }

  .robotImage {
    width: 40px;
    height: 40px;
  }

  .userName,.welcomeTo {
    font-size: 18px;
    font-family: 'Arial', sans-serif;
  }

  .moreicon {
    width: 33px;
    height: 33px;
  }
}


/* @media  (min-width: 300px) and (max-width: 600px) {
  .menulogoutt {
   width: 25px !important;
   height:14rem !important;
   padding: -40px !important;
  }

} */


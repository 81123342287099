.css-1nxyqcu {
  padding: 20 !important;
  margin-top: -15px !important;
}

@media (min-width: 220px) and (max-width: 429px) {
  .docname {
    font-size: 7px !important;
  }

  .pdficondocname{
    transform: scale(110%) !important;
  }

  .css-1nxyqcu {
    padding: 20 !important;
    margin-top: -35px !important;
  }

  .chatclosebutton{
    margin-top: -7px !important;
  }
}

@media (min-width: 1024px) {
  .pdficondocname{
    transform: scale(130%) !important;
    position: relative !important;
    left: 45px !important;
  }

  .chatclosebutton{
    margin-top: -7px !important;
  }
}
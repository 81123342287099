@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.arrowIcon {
    margin-left: 80px !important;
    cursor: pointer;
}

.labels {
    color: #777676;
    font-size: 14px !important;
    line-height: 21px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important;
}

.loginsimplfyText {
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

@media (min-width : 300px) and (max-width:345px){
   .loginsimplfyText{
    font-size: 15px!important;
  margin-top:75px !important ;

   } 
}

@media (min-width : 346px) and (max-width:379px){

    .loginsimplfyText{
     font-size: 15px!important;
     margin-top:75px !important ;
    } 
 }

 @media (min-width : 381px) and (max-width:450px){
    .loginsimplfyText{
     font-size: 16px!important;
     margin-top:67px !important ;
    } 
 }

@media (min-width : 628px) and (max-width:770px){
    .loginsimplfyText{
     font-size: 16px!important;
     margin-top:14px !important ;
   margin-bottom: 20px !important;
   margin-left: 5px !important;
    } 
 }

 @media (min-width : 780px) and (max-width:1040px){
    .loginsimplfyText{
     font-size: 16px!important;
     margin-top:9px !important ;
   margin-bottom: 20px !important;
   margin-left: 3px !important;
    } 
 }

.textfield {
    margin-top: 3px !important;
}

.createpagetextfields {
    margin-top: 0 !important;
}

.title {
    font-size: 28px !important;
    font-weight: 700 !important
}

.textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0D6EFD !important;
}

.createpagetextfields .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0D6EFD !important;
}
.prompts-main {
  display: flex;
  flex-direction: column;
  background-color: white;
  flex-grow: 1;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  border-radius: 10px;
  padding: 20px;
}
.promptheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
 
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
.edit-icon {
  width: 30px !important;
  height: 30px !important;
  font-size: large;
  background-color: #E5F1FF;
  color: #0075FF;
  padding: 6px;
  border-radius: 13px;
}

.delete-icon {
  width: 30px !important;
  height: 30px !important;
  font-size: large;
  background-color: #FFEAEA;
  color: #F44336;
  padding: 6px;
  border-radius: 13px;
}
 
@media (max-width: 768px) {
 
  .promptconfigtext {
    font-size: 14px !important;
    text-align: center !important;
 
 
  }
 
  .prmpt-search {
    padding-right: 30px;
    display: 'inline-block';
    width: 100rex !important;
  }
 
 
  .form-container {
    width: 100%;
    padding: 10px;
  }
 
  .cancel-button,
  .add-button {
    width: 100%;
    margin-bottom: 10px;
  }
 
  .drawer-content {
    padding: 16px;
  }
 
  .MuiOutlinedInput-root {
    font-size: 1rem;
  }
 
  .css-1hec5sm-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
    font-size: 11px;
  }
 
}
.cell-border-right {
  border-right: 1px solid rgba(224, 224, 224, 1); /* Border styling */
}
 
@media (min-width:600px) and (max-width: 889px) {
  .Buttons_ESD3 {
    line-height: 1 !important;
  }
}
 
@media (max-width: 768px) {
  .Buttons_ESD3 {
    line-height: 1 !important;
  }
}
 
@media (max-width: 768px) {
  .Buttons_ESD3 {
    line-height: 1 !important;
  }
}
 
@media(min-width:360px) and (max-width: 500px) {
  .css-1izfg5m {
 
    width: 360px !important;
  }
}
 
@media(min-width:320px) and (max-width: 359px) {
  .css-1izfg5m {
 
    width: 250px !important;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Typography{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 600!important;
  font-style: normal!important;
}
.fontstyleet{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
}

.fontstylee{
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500!important;
    font-style: normal!important;
}

.fontstyleeb{
  font-family: 'Poppins', sans-serif!important;
  font-weight: 600!important;
  font-style: normal!important;
}

.link-underline {
  text-decoration: underline;
  text-underline-offset: 2px; /* Adjust the offset if needed */
}

/*searchprimary*/
.search-bar {
  margin-right: 16px !important;
}

.search-bar .MuiOutlinedInput-root {
  border-radius: 9px !important;
}

.search-bar .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0D6EFD !important;
}

.search-bar .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0D6EFD;
}

/*selectprimary*/
.inputfields .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0D6EFD !important;
}

.inputfields .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0D6EFD;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: "#f4f8fd";
}

.app {
  display: flex;
  position: relative;
  justify-content: center;
  background-color: "#f4f8fd";

}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #e0e0e0;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.sideBar {
  height: 100%;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
  color: unset !important;
}
.pro-sidebar .pro-menu a:hover {
  color: unset !important;
}


@import "rsuite/dist/rsuite.css";

.css-1uooz3j-MuiBadge-badge {
    font-size: 8px !important;
    min-width: 14px !important;
    line-height: 1 !important;
    padding: 0 0px !important;
    height: 14px !important;
    top: 4px !important;
    right: 6px !important;
}

.addCommentText {
    color: #565656 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
}

.remarkstext {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
}

.commentsText {
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
}


.activelabel {
    background-color: rgb(229, 241, 255) !important
}

.inProgress {
    background-color: #FFC5C5 !important;
    color: #df0404 !important;
    border-color: #df0404 !important;
}

.successlabel {
    background-color: #a6e7d8 !important;
}

.onHoldLabel {
    background-color: #fdf0e0 !important;
    color: #f3982e !important;
    border-color: #f3982e !important;
}

.filterIcon {
    min-width: 38px !important;
    padding: 6px 15px !important;
}

.rs-stack {
    gap: 7px !important;
}

.css-tdpf08 {
    align-items: baseline !important;
}

.cursor {
    cursor: pointer;
    text-decoration: underline;
}

.previewCommentText {
    cursor: pointer;
    text-decoration: underline;
}

.previewRemarkText {
    cursor: pointer;
    text-decoration: underline;
}

.documentname {
    cursor: pointer;
    text-decoration: underline;
}

.rs-picker-popup.rs-picker-popup-daterange {
    border-radius: 18px !important;
}

.button-enabled {
    background-color: #055ad9 !important;
    color: white !important;
}

.fontstyleet .rs-picker-toggle-placeholder {
    font-size: 12px !important;
}


.button-disabled {
    background-color: rgb(122, 0, 0) !important;
    color: white !important;
}

.css-dydjmx-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: unset !important;
}


@media (min-width: 320px) and (max-width: 740px) {
    .cardText {
        font-size: 12px;
        padding-right: 0px;
    }

    .cardTextCount {
        font-size: 15px;
    }

    .cardContent {
        padding: 9px;
    }

    .cardImages {
        width: 20px;
    }

    .imagebackgrounds {
        width: 40px;
        height: 40px;
    }

    .rows {
        margin: 0px;
    }

    .maincontainer {
        padding: 0px;
    }

    .MicroSoftRFPDetails_heading {
        font-size: 15px !important;
    }

    .rfpDtailsContainer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .meetingNotification {
        max-width: 235px !important;
    }

    .meetingNotification_image {
        width: 50px;
    }

    .alertText1 {
        font-size: 14px !important;
    }

    .alertText {
        font-size: 12px !important;
    }

    .chatbotpage {
        padding: 0px !important;
    }

    .fileuploadModal {
        width: 300px !important;
        height: 350px !important;

    }

    .addRfpFile {
        width: 40px !important;
    }

    .fileuploadText {
        font-size: 10px !important;
    }

    .fileuploadBox {
        padding: 0px !important;
        width: 100% !important;
        margin-left: 0px !important;
    }

    .addRfpText {
        font-size: 12px !important;
    }

    .RfpDetailsButton {
        width: 75% !important;
    }

    .viewButton {
        margin-top: 3px !important;
    }

    .commentsModal {
        width: 300px !important;
    }

    .commentsText {
        font-size: 16px !important;
    }

    .addCommentText {
        font-size: 13px !important;
    }

    .docuploadModal {
        width: 300px !important;
    }

    .addRfpdoc {
        width: 30px !important;
        height: 30px !important;
    }

    .docuploadBox {
        padding: 6px !important;
        width: 100% !important;
        margin-left: 0px !important;
    }

    .dragText {
        font-size: 12px !important;
    }

    .docName {
        font-size: 11px !important;
    }

    .css-1u5sxkl-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
        height: 40px !important;
    }

    .css-2qm3gv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 40px !important;
    }

    .uploadDocName {
        font-size: 11px !important;
    }

    .selectFilters {
        font-size: 11px;
    }

    .filterModalHeading {
        font-size: 13px !important;
    }

    .dialogpaperstyles {
        margin: none !important;
    }
}


@media(min-width:320px) and (max-width: 330px) {
    .microsoftrfpdetails {
        font-size: 13px !important;
    }
}

@media (min-width: 271px) and (max-width: 425px) {
    .subtextfu {
        font-size: 8px !important;
    }

    .fileuploadText {
        font-size: 9px !important;
    }

    .fileuploadTextsub {
        font-size: 9px !important;
    }

    .pdfnamefu {
        font-size: 8px !important;
        margin-left: -30px !important;

    }
}

@media (min-width: 594px) and (max-width: 770px) {
    .fileuploadModal {
        width: 450px !important;
        height: 360px !important;
    }

}

@media (min-width: 776px) and (max-width: 996px) {
    .fileuploadModal {
        width: 450px !important;
        height: 410px !important;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .cardImages {
        width: 30px;
    }

    .imagebackgrounds {
        width: 45px;
        height: 45px;
    }

    .maincontainer {
        padding-right: 0px;
        position: relative;


    }

    .MicroSoftRFPDetails_heading {
        font-size: 14px;
    }

    .fileuploadModal {
        width: 450px !important;
    }

    .addRfpFile {
        width: 60px !important;
        height: 60px !important;
    }

    .fileuploadText {
        font-size: 13px !important;
    }

    .fileuploadBox {
        width: 100% !important;
        margin-left: 0px !important;

    }

    .addRfpText {
        font-size: 13px !important;
    }

    .RfpDetailsButton {
        width: 75% !important;
    }

    .progressbar {
        margin-top: 0px !important;
    }

    .commentsModal {
        width: 400px !important;
    }

    .commentsText {
        font-size: 16px !important;
    }

    .addCommentText {
        font-size: 13px !important;
    }

    .meetingNotification {
        max-width: 400px !important;
    }

    .meetingNotification_image {
        width: 65px;
    }

    .docuploadModal {
        width: 400px !important;
    }

    .addRfpdoc {
        width: 45px !important;
        height: 45px !important;

    }

    .docuploadBox {
        padding: 23px !important;

        width: 100% !important;
        margin-left: 0px !important;
    }

    .dragText {
        font-size: 14px !important;
    }

    .docName {
        font-size: 14px !important;
    }

    .css-1u5sxkl-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
        height: 40px !important;
    }

    .css-2qm3gv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 40px !important;
    }

    .uploadDocName {
        font-size: 14px !important;
    }

    .selectFilters {
        font-size: 10px !important;
    }


    .selectFilters {
        font-size: 12px !important;
    }

    .filterModalHeading {
        font-size: 15px !important;
    }

}

@media (min-width:376px) and (max-width:425px) {
    .meetingNotification {
        max-width: 260px !important;
    }
}

@media (min-width: 769px) and (max-width: 986px) {

    .datepicker,
    .inputfields {
        min-width: 130px !important;
    }

    .due-date-col {
        margin-top: 10px;
    }

    .filterButton {
        margin-top: 10px;
    }

    .addButton {
        margin-top: 10px;
        margin-left: 14px;
    }

    .MicroSoftRFPDetails_heading {
        font-size: 18px !important;
    }

    .meetingNotification {
        max-width: 430px !important;
    }

    .alertText1 {
        font-size: 18px !important;
    }

    .alertText {
        font-size: 15px !important;
    }


    .fileuploadModal {
        width: 550px !important;
    }

    .addRfpFile {
        width: 70px !important;
        height: 70px !important;
    }

    .fileuploadText {
        font-size: 15px !important;
    }

    .fileuploadBox {
        padding: 18px !important;
        width: 100% !important;
        margin-left: 0px !important;

    }

    .addRfpText {
        font-size: 15px !important;
    }

    .RfpDetailsButton {
        width: 75% !important;
    }

    .progressbar {
        margin-top: 0px !important;
    }

    .viewButton {
        margin-top: 0px !important;
    }

    .commentsModal {
        width: 500px !important;
    }

    .commentsText {
        font-size: 16px !important;
    }

    .addCommentText {
        font-size: 13px !important;
    }

}

@media (min-width: 987px) and (max-width: 1200px) {

    .datepicker,
    .inputfields {
        min-width: 100px !important;
    }

    .cardImages {
        width: 22px;
    }

    .imagebackgrounds {
        width: 40px !important;
        height: 38 !important;
    }

    .cardText {
        font-size: 14px !important;
        padding-right: 0px !important;
    }

    .cardTextCount {
        font-size: 15px !important;
    }

    .cardContent {
        padding: 9px !important;
    }

    .maincontainer {
        padding-right: 0px;
        position: relative;
        bottom: 26px
    }

    .MicroSoftRFPDetails_heading {
        font-size: 18px !important;
    }

    .meetingNotification {
        max-width: 450px !important;
    }

    .alertText1 {
        font-size: 18px !important;
    }

    .alertText {
        font-size: 15px !important;
    }

    .fileuploadModal {
        width: 480px !important;
    }

    .addRfpFile {
        width: 70px !important;
        height: 70px !important;
    }

    .fileuploadText {
        font-size: 15px !important;
    }

    .fileuploadBox {
        width: 100% !important;
        margin-left: 0px !important;

    }

    .addRfpText {
        font-size: 15px !important;
    }

    .RfpDetailsButton {
        width: 75% !important;
    }

    .progressbar {
        margin-top: 0px !important;
    }

    .viewButton {
        margin-top: 0px !important;
    }

    .commentsModal {
        width: 100% !important;
    }

    .commentsText {
        font-size: 17px !important;
    }

    .addCommentText {
        font-size: 14px !important;
    }


    .docuploadModal {
        width: 420px !important;
    }

    .addRfpdoc {
        width: 45px !important;
        height: 45px !important;

    }

    .docuploadBox {
        padding: 20px !important;

        width: 100% !important;
        margin-left: 0px !important;
    }

    .dragText {
        font-size: 14px !important;
    }

    .docName {
        font-size: 14px !important;
    }

    .css-1u5sxkl-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
        height: 40px !important;
    }

    .css-2qm3gv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 40px !important;
    }

    .uploadDocName {
        font-size: 13px !important;
    }

    .selectFilters {
        font-size: 13px !important;
    }

    .filterModalHeading {
        font-size: 15px !important;
    }



}

@media (min-width: 1201px) and (max-width: 1440px) {
    .cardImages {
        width: 31px;
    }

    .imagebackgrounds {

        width: 50px;
        height: 50px;
    }

    .rows {
        margin: 11px;
    }

    .maincontainer {

        padding-right: 0px;
        position: relative;
        bottom: 26px
    }

    .MicroSoftRFPDetails_heading {
        font-size: 20px !important;
    }

    .meetingNotification {
        max-width: 450px !important;
    }

    .alertText1 {
        font-size: 18px !important;
    }

    .alertText {
        font-size: 15px !important;
    }

    .fileuploadModal {
        width: 500px !important;
    }

    .addRfpFile {
        width: 70px !important;
        height: 70px !important;
    }

    .fileuploadText {
        font-size: 15px !important;
    }

    .fileuploadBox {
        width: 100% !important;
        margin-left: 0px !important;

    }

    .addRfpText {
        font-size: 15px !important;
    }

    .RfpDetailsButton {
        width: 75% !important;
    }

    .progressbar {
        margin-top: 0px !important;
    }

    .viewButton {
        margin-top: 0px !important;
    }

    .commentsModal {
        width: 100% !important;
    }

    .commentsText {
        font-size: 20px !important;
    }

    .addCommentText {
        font-size: 15px !important;
    }

    .docuploadModal {
        width: 430px !important;
    }

    .addRfpdoc {
        width: 50px !important;
        height: 50px !important;

    }

    .docuploadBox {
        padding: 27px !important;

        width: 100% !important;
        margin-left: 0px !important;
    }

    .dragText {
        font-size: 14px !important;
    }

    .docName {
        font-size: 14px !important;
    }

    .css-1u5sxkl-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
        height: 50px !important;
    }

    .css-2qm3gv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 50px !important;
    }

    .uploadDocName {
        font-size: 13px !important;
    }
}

@media (min-width: 1024px) {
    .cardImages {
        width: 31px;
    }

    .imagebackgrounds {

        width: 50px;
        height: 50px;
    }

    .rows {
        margin: 11px;
    }

    .maincontainer {
        padding-right: 0px;
        position: relative;
        bottom: 26px
    }

    .MicroSoftRFPDetails_heading {
        font-size: 20px !important;
    }

    .meetingNotification {
        max-width: 650px !important;

    }

    .fileuploadModal {
        width: 550px !important;
        height: 430px !important;
    }

    .addRfpFile {
        width: 70px !important;
        height: 70px !important;
    }

    .fileuploadText {
        font-size: 15px !important;
    }

    .fileuploadBox {
        padding: 17px !important;
        width: 100% !important;
        margin-left: 0px !important;

    }

    .addRfpText {
        font-size: 15px !important;
    }

    .RfpDetailsButton {
        width: 75% !important;
    }

    .progressbar {
        margin-top: 0px !important;
    }

    .viewButton {
        margin-top: 0px !important;
    }

    .commentsModal {
        width: 650px !important;
    }

    .commentsText {
        font-size: 25px !important;
    }

    .addCommentText {
        font-size: 17px !important;
    }


    .docuploadModal {
        width: 430px !important;
    }

    .addRfpdoc {
        width: 50px !important;
        height: 50px !important;

    }

    .docuploadBox {
        padding: 27px !important;

        width: 100% !important;
        margin-left: 0px !important;
    }

    .dragText {
        font-size: 14px !important;
    }

    .docName {
        font-size: 14px !important;
    }

    .css-1u5sxkl-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
        height: 50px !important;
    }

    .css-2qm3gv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 50px !important;
    }

    .uploadDocName {
        font-size: 13px !important;
    }

}


@media (min-width: 300px) and (max-width: 378px) {
    .commentsboxeditrfp {
        position: absolute !important;
        top: -80px !important;
        left: -60px !important;
        width: 45rex !important;
         height: 65rex !important;
        justify-content: center !important;
        overflow: scroll !important;

    }
}

@media (min-width: 322px) and (max-width: 378px) {
    .commentsboxeditrfp {
        position: absolute !important;
        top: -80px !important;
        left: -30px !important;
        width: 45rex !important;
         height: 65rex !important;
        justify-content: center !important;
        overflow: scroll !important;
    }
}

@media (min-width: 379px) and (max-width: 438px) {
    .commentsboxeditrfp {

        position: absolute !important;
        top: -80px !important;
        left: -30px !important;
        width: 52rex !important;
         height: 65rex !important;
        justify-content: center !important;
        overflow: scroll !important;

    }
}

@media (min-width: 449px) and (max-width: 788px) {
    .commentsboxeditrfp {
        position: absolute !important;
        top: -80px !important;
        left:30px !important;
        width: 69rex !important;
        height: 65rex !important;
        overflow: scroll !important;

    }
}

@media (min-width: 300px) and (max-width: 345px) { 
    .rfpdetailscnn{
      transform: scale(60%) !important;
    }

    .commentsrfpd{
        margin-left: -43px !important;
        position: relative  !important;
        top: 10px !important;
    }

    .badgenotificationmd{
        margin-left: -55px !important;
    }

    .rfpdetailscnbn{
        transform: scale(65%) !important;
    }


}   

 @media (min-width: 347px) and (max-width: 604px) { 
    .rfpdetailscnn{
      transform: scale(60%) !important;
    }

    .commentsrfpd{
        margin-left: -50px !important;
        position: relative  !important;
        top: 10px !important;
    }

    .badgenotificationmd{
        margin-left: -65px !important;
    }

    .rfpdetailscnbn{
        transform: scale(65%) !important;
    }


} 